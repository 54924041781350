'use client';

import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function getPWADisplayMode() {
  const navigator: any = window.navigator;
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}


export default function PWACheck() {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(getPWADisplayMode() === "browser");
  }, []);

  if (typeof window === 'undefined') {
    return;
  }

  const PWACheckClosed = localStorage.getItem("PWACheckClosed");  
  
  // Initialize deferredPrompt for use later to show browser install prompt.
  let deferredPrompt: any;

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    setOpen(true);
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
  
  window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    setOpen(false);
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
  });
  
  window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
    let displayMode = 'browser';
    if (evt.matches) {
      displayMode = 'standalone';
    }
    // Log display mode change to analytics
    console.log('DISPLAY_MODE_CHANGED', displayMode);
  });

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    // Hide the app provided install promotion
    setOpen(false);
    localStorage.setItem("PWACheckClosed", "yes");
    // Show the install prompt
    if(deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
    }
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt = null;
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        <a target='_blank' rel="noreferrer" href='https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/'>How do I do that?</a>
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  let message = "For the best experience, install the app.";

  if(PWACheckClosed === "yes") {
    message = "For the best experience (and for this message to stop reappearing), install the app."
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
}